import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import Icon from '../ui/Icon';
import MenuItems from '../../navigation/MenuItems';

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.neutrals.beige.main,
    width: 342,
  },
  list: {
    height: 'auto',
  },
  fullList: {
    width: 'auto',
  },
  nested: {
    paddingLeft: theme.spacing(8),
  },
  gutters: {
    paddingLeft: 0,
    paddingRight: 64,
  },
  primary: {
    fontStyle: 'normal',
  },
  button: {
    textTransform: 'none',
  },
}));

export default function DrawerMenu() {
  const classes = useStyles();
  const theme = useTheme();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [expanded, setExpanded] = React.useState(false);

  const handleOpenSubMenu = () => {
    setExpanded(!expanded);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setExpanded(!open);
  };

  const drawItemMenu = (route) => {
    if ('children' in route) {
      return (
        <>
          <ListItemText
            key={route.label}
            primary={route.label}
            primaryTypographyProps={{ variant: 'subtitle1' }}
            onClick={handleOpenSubMenu}
          />
          {expanded ? <ChevronLeftRounded /> : <ChevronRightRounded />}
        </>
      );
    }

    return (
      <ListItemText
        key={route.label}
        primary={route.label}
        primaryTypographyProps={{ variant: 'subtitle1' }}
      />
    );
  };

  const drawCollapse = (anchor, route) => {
    const childrenLenght = route.children.length;
    return (
      <>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {route.children.map((child, idx) => (
              <ListItem
                button
                className={classes.nested}
                component="a"
                href={child.path}
                key={child.label}
              >
                <ListItemText
                  classes={{
                    primary: childrenLenght === idx + 1 ? classes.button : classes.primary,
                  }}
                  onClick={toggleDrawer(anchor, false)}
                  primary={child.label}
                  primaryTypographyProps={{
                    variant: childrenLenght === idx + 1 ? 'button' : 'subtitle2',
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </>
    );
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <img
            alt="Tani Salud Brand Logo"
            className={classes.logo}
            src={`${process.env.PUBLIC_URL}/images/smart-logo.png`}
            style={{ height: 34, margin: '41px 100px 60px 33px', width: 34 }}
          />
        </Grid>
      </Grid>
      <List component="nav" aria-labelledby="navigation-drawer-menu">
        {MenuItems.map((route) => (
          <div key={route.label}>
            <ListItem
              button
              classes={{ gutters: classes.gutters }}
              component="a"
              href={route.path}
              key={route.label}
              onClick={'children' in route ? null : toggleDrawer(anchor, false)}
              onKeyDown={'children' in route ? null : toggleDrawer(anchor, false)}
              style={{ marginBottom: expanded ? 0 : 40, marginLeft: 33 }}
            >
              {drawItemMenu(route)}
            </ListItem>
            {'children' in route ? drawCollapse(anchor, route) : <></>}
          </div>
        ))}
      </List>
      <Grid
        container
        spacing={3}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
        style={{ marginBottom: 63 }}
      >
        <Grid item xs={8}>
          <Button
            variant="contained"
            style={{
              backgroundColor: theme.palette.portland.main,
              color: theme.neutrals.white,
            }}
            disableElevation
          >
            Agenda una cita ahora
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  return (
    <React.Fragment key="right">
      <IconButton aria-label="menu" edge="end" onClick={toggleDrawer('right', true)}>
        <Icon name="menu" />
      </IconButton>
      <Drawer
        anchor="right"
        classes={{ paper: classes.paper }}
        ModalProps={{ onBackdropClick: toggleDrawer('right', false) }}
        open={state.right}
        onClose={toggleDrawer('right', false)}
        variant="temporary"
      >
        {list('right')}
      </Drawer>
    </React.Fragment>
  );
}
