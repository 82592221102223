/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React from 'react';
import Icons from '../../assets/icons/icons.svg';

// eslint-disable-next-line react/prop-types
const SVGIcon = ({ name, fillColor, size, strokeColor }) => (
  <svg
    className={`icon icon-${name}`}
    fill={fillColor}
    width={size}
    height={size}
    stroke={strokeColor}
  >
    <use xlinkHref={`${Icons}#icon-${name}`} />
  </svg>
);

const Icon = ({ container, name, fillColor, size, strokeColor, style = {} }) =>
  container ? (
    <i style={style.container} className={`icon-${name}-container`}>
      <SVGIcon name={name} fillColor={fillColor} size={size} strokeColor={strokeColor} />
    </i>
  ) : (
    <SVGIcon name={name} fillColor={fillColor} size={size} strokeColor={strokeColor} />
  );

Icon.defaultProps = {
  container: false,
  fillColor: '#FFF',
  size: 20,
  strokeColor: '',
  style: {
    container: {},
  },
};

Icon.propTypes = {
  container: PropTypes.bool,
  name: PropTypes.string.isRequired,
  fillColor: PropTypes.string,
  size: PropTypes.number,
  strokeColor: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;
