import React from 'react';
import PropTypes from 'prop-types'
import {Footer, TopBar} from '../../components/ui';
import CalculatorView from './CalculatorView';

 function CalculatorContainer ({ percent }) {
  return (
    <>
      <TopBar />
      <CalculatorView percent={percent} />
      <Footer />
    </>
  );
}

CalculatorContainer.propTypes ={ 
  percent: PropTypes.number.isRequired
}
export default CalculatorContainer