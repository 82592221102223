import React from 'react';
import PropTypes from 'prop-types'
import { Container, useMediaQuery, useTheme } from '@material-ui/core' 
import { Section } from '../boxes';



function Hero ({ cover, children}) {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.up('md'))
    return (
        <Section
        backgroundColor={theme.palette.primary.main}
        backgroundImage={`url(${cover})`}
        zIndex={90}
        isHeader
        elevated
        isImage
        height={481}
      >
        <Container
          fixed
          maxWidth="lg"
          style={{
            textAlign: isTablet ? 'left' : 'center',
            display: 'flex',
            alignContent: 'center',
            alignItems: isTablet ? 'flex-start' : 'center',
            justifyContent: isTablet ? 'center' : 'flex-end',
            height: '100%',
            flexFlow: 'column',
            paddinBottom: theme.spacing(4)
          }}
        >
          { children }

        </Container>
      </Section>
    )
}

Hero.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ]).isRequired,
    cover: PropTypes.string.isRequired,
}

export default Hero;