import PropTypes from 'prop-types'
import React, {useState, useEffect, useRef} from 'react'
import {CardMedia} from '@material-ui/core'


const LazyCardMedia = ({alt, component, classes, image, src, className,width, height, style}) => {
  const composedClass = className || classes || 'div';
  const [visible, setVisible] = useState(false);
  const placeholderRef = useRef(null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!visible && placeholderRef.current) {
      const observer = new IntersectionObserver(([{intersectionRatio}]) => {
        if (intersectionRatio > 0) {
          setVisible(true);
        }
      });
      observer.observe(placeholderRef.current);
      return () => observer.disconnect();
    }
  }, [visible, placeholderRef]);

  return (visible
      ?
      <CardMedia
        width={width}
        height={height}
        component={component}
        style={style}
        className={ composedClass }
        image={image || src}
        alt={alt}
      />
      :
      <div style={{backgroundColor: '#EEE'}} className={classes} aria-label={alt}
           ref={placeholderRef}/>
  );
};

LazyCardMedia.defaultProps = {
  classes: '',
  className: undefined,
  component: undefined,
  alt: 'image',
  width: undefined,
  height: undefined,
  src: undefined,
  image: undefined,
  style: undefined,
}

LazyCardMedia.propTypes = {
  alt: PropTypes.node,
  component: PropTypes.string,
  classes: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  image: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  // eslint-disable-next-line
  style: PropTypes.object,
}

export default LazyCardMedia
