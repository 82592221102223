import React from 'react';
import {Paper} from '@material-ui/core';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {containers} from '../../styles/GlobalStyles'


const useStyle = makeStyles(theme => ({
  root: {
    ...containers.section,
    paddingBottom: 30,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    borderRadius: '0 0 0 30px',
    overflowX: 'hidden',
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none !important',
      borderRadius: 0,
      overflowX: 'initial',
    },
   //  overflowX: 'hidden',


  },
  isImage: {

    [theme.breakpoints.down('sm')]: {
      borderRadius: '0 0 0 60px !important',
    },
  },
  isVisible: {
    display: 'block'
  },
  isHeader: {
    marginTop: 0,
    paddingTop: 30,
  },
  isOffset: {
    marginTop: -35,
    paddingTop:  65,
  },
  hasInnerSection: {
    paddingTop: 0,
    [theme.breakpoints.up('md')]: {
      paddingBottom: 0,
    },
    overflow: 'hidden',
  },
  isCard: {
    marginTop: 0,
    paddingTop: 16
  },
  isBlogHeader: {
    borderRadius: '0 60px 60px 0 !important',
    width: '95% !important'
  },
  isTabBar: {
    padding: '10px 0',
    paddingTop:  45,
    [theme.breakpoints.up('md')]: {
      top: 0,
      position: 'sticky',
      // padding: '10px 0 !important' ,
      marginTop: 0,
      // maxWidth: theme.breakpoints.values.md,
      marginLeft: 'auto',
      marginRight: 'auto',
      // borderRadius: 20,
      // boxShadow: '0px 6px 8px rgba(11, 47, 64, 0.1) !important'
    },
  },
  isTabBarOffset : {
    [theme.breakpoints.up('md')] : {
      marginTop: -30,
    }
  },
  isReviewCard: {
    paddingTop: 16,
    marginTop: 0,
    borderRadius: '0 0 0 30px',
  },
  isPaddinglessMobile: {

    [theme.breakpoints.down('md')] :{
      padding: 0
    },

  },
  isOffsetPlus : { 
    [theme.breakpoints.up('md')] :{
      marginTop: -40,
      paddingTop: 0,
      paddingBottom: 0,
    }
  },
  isPaddingless: {
    padding: '30px 0 0 ',
    marginTop: 0,
  }
}))

const Section = ({
  // controla el tamaño del radio; hay una diferencia entre los contenidos de texto y los demás
  isImage,

  // controla si es que va abajo de la tarjeta previa o no. Los headers van 'flush
  // con el resto elemento superior a ellos
  isHeader,
  // usar si es que hay una sección dentro de otra (surgeon detail)
  hasInnerSection,
  backgroundColor,
  backgroundImage,
  children,
  elevated,
  height,
  className,
  isCard,
  isVisible,
  isBlogHeader,
  isTabBar,
  isReviewCard,
  isPaddinglessBottom,
  isOffsetPlus,
  isPaddingless,
  style,
  // top,
  zIndex }) => {
  const classes = useStyle();

  // selects correct array of classes depending on props
  const offsetPlusClass = () => {
    if(isOffsetPlus && isTabBar) { 
      return classes.isTabBarOffset
    }
    return classes.isOffsetPlus
  }

  const rootClasses = [
    classes.root,
    isImage ? classes.isImage : '',
    isHeader? classes.isHeader: classes.isOffset,
    hasInnerSection? classes.hasInnerSection : '',
    isCard ? classes.isCard : '',
    isBlogHeader ? classes.isBlogHeader : '',
    isTabBar ? classes.isTabBar : '',
    isReviewCard ? classes.isReviewCard : '',
    isPaddinglessBottom ? classes.isPaddinglessMobile : '',
    isOffsetPlus ? offsetPlusClass() : '',
    isPaddingless ? classes.isPaddingless : '',
    className,
    ].join(' ')
  return (
    <>
      
      <Paper
        elevation={elevated ? 3 : 0}
        className = {rootClasses}
        style={{
          // el elemento neceista tener position relative o similares para poder usar z index
          zIndex,
          height,
          // Background config
          ...(backgroundColor ? {backgroundColor} : undefined),
          backgroundImage,
          display: isVisible ? classes.isVisible : 'none',
          ...style
        }}
      >
        {children}
      </Paper>
    </>
  )};

Section.defaultProps = {
  backgroundColor: undefined,
  zIndex: undefined,
  backgroundImage: undefined,
  isHeader: false,
  isImage: false,
  isVisible: true,
  height: 'max-content',
  children: undefined,
  elevated: false,
  hasInnerSection: false,
  className: '',
  isCard: false,
  isBlogHeader: false,
  isTabBar: false,
  isReviewCard: false,
  isPaddinglessBottom: false,
  isOffsetPlus: false,
  isPaddingless: false,
  style: {}
}

Section.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  elevated: PropTypes.bool,
  isHeader: PropTypes.bool,
  isVisible: PropTypes.bool,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // top: PropTypes.number.isRequired,
  zIndex: PropTypes.number,
  isImage: PropTypes.bool,
  hasInnerSection: PropTypes.bool,
  isCard: PropTypes.bool,
  isBlogHeader: PropTypes.bool,
  isTabBar: PropTypes.bool,
  isReviewCard: PropTypes.bool,
  isPaddinglessBottom: PropTypes.bool,
  isPaddingless: PropTypes.bool,
  isOffsetPlus: PropTypes.bool,
  // eslint-disable-next-line
  style: PropTypes.any
};

export default Section;
