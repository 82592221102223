// import theme from "./taniTheme"

export const typography = {
  fontFamily: 'Open Sans, sans-serif',
  h1: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.8rem',
    lineHeight: '120%',
    '@media (minWidth: 960px)': {
      fontSize: '2rem'
    },
    transform: 'translateY(10px)'
  },
  h2: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.4rem',
    lineHeight: '26px',
    '@media (minWidth: 960px)': {
      fontSize: '1.6rem',
    },
    transform: 'translateY(8px)'
  },
  h2Bold: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '1.4rem',
    lineHeight: '26px',
    '@media (minWidth: 960px)': {
      fontSize: '1.6rem'
    }
  },
  h3: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1.1rem',
    lineHeight: '20.8px',
    '@media (minWidth: 960px)': {
      fontSize: '1.2rem'
    }
  },
  h4: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '130%',
    '@media (minWidth: 960px)': {
      fontSize: '1.1rem'
    }
  },
  subtitle1: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1.1rem',
    lineHeight: '20.8px',
  },
  subtitle2: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'italic',
    fontWeight: 400,
    fontSize: '1.1rem',
    lineHeight: '20.8px',
  },
  bodyItalic: {
    fontStyle: 'italic'
  },
  body1: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '18.2px',
  },
  body1bold: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '18.2px',
  },
  body2: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '18.2px',
  },
  body2bold: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '18.2px',
  },
  button: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '18.2px',
  },
  labelButton: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '1rem',
    lineHeight: '14px',
  },
  smallButton: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '.9rem',
    lineHeight: '15.6px',
  },
  small: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.9rem',
    lineHeight: '130%',
  },
  smallBold: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '.9rem',
    lineHeight: '130%',
  },
  smallItalic: {
    fontStyle: 'italic',
    fontSize: '.9rem',
    lineHeight: '130%'
  },
  caption: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '.9rem',
    lineHeight: '15.6px',
  },
  overline: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '.9rem',
    lineHeight: '15.6px',
  },
  bigBold: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontSize: '1.1rem',
    fontWeight: 700
  },
  big: {
    fontFamily: `${"'Open Sans', sans-serif"}`,
    fontSize: '1.1rem',
  },
  priceTag: {
    fontFamily: `${"'Baloo Tammudu 2', sans-serif"}`,
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '100%',
  },
}

export const containers = {
  section: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    width: '100%',
  },
  cardContainer: {
    display: 'flex',
    flexFlow: 'column',
    width: '100%',

    paddingTop: 8,
    '& > * ': {
      marginBottom: 8,
    },
    '& > *:last-child': {
      marginBottom: 0
    }
  },
  inputList: {
    display: 'flex',
    flexFlow: 'column',
    paddingTop: 8,
    '& .input': {
      marginBottom: 24
    }
  },
  buttons: {
    '&:not(.is-horizontal)': {
      display: 'flex',
      flexFlow: 'column',
      alignItems: 'center',
      '& > *': {
        marginBottom: '8px',
      },
      '& > *:last-child': {
        marginBottom: 0
      }
    }

  },
  baseContainer: {
    padding: ' 16px'
  }
}
export const helpers = {
  hr: {
    border: 'none',
    height: '1px',
    margin: `16px 0`,
    flexShrink: 0,
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
  },
  button: {
    borderRadius: 30,
    padding: '10px 24px',
    margin: 0,
    textTransform: 'none',
    marginRight: 'auto',
    marginLeft: 'auto',
    textAlign: 'center'
  }
}

export default {
  ...typography,
  ...containers,
  ...helpers,
}
