/* eslint-disable react/forbid-prop-types */
import { Grid } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.neutrals.beige.main,
    borderRadius: 5,
    border: 'none',
    display: 'flex',
    marginBottom: 15,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
  cover: {
    borderRadius: 50,
    height: 79,
    marginLeft: 14,
    marginTop: 12,
    marginBottom: 15,
    width: 79,
  },
}));

const HorizontalCardLayout = ({
  imageURL,
  titleCard,
  subTitleSection,
  descriptionSection,
  footerSection,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card elevation={0} className={classes.root}>
      <CardMedia component="img" className={classes.cover} src={imageURL} title="Alt title" />
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h4" variant="h4" style={{ color: theme.neutrals.black_blue }}>
            {titleCard}
          </Typography>
          <Typography
            variant="body2"
            style={{
              color: theme.neutrals.black_blue,
              fontSize: 12,
              lineHeight: '15.6px',
              marginBottom: 7,
            }}
          >
            {subTitleSection}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <Rating
                precision={0.5}
                readOnly
                size="small"
                value={descriptionSection.currentRating}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography
                variant="body2"
                style={{
                  color: theme.neutrals.black_blue,
                  fontWeight: 700,
                  fontSize: 12,
                  lineHeight: '15.6px',
                  textAlign: 'center',
                }}
              >
                {descriptionSection.currentRating}
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Typography
                variant="body2"
                style={{
                  color: theme.neutrals.black_blue,
                  fontSize: 12,
                  lineHeight: '15.6px',
                }}
              >
                {`(${descriptionSection.totalReviews} reseñas)`}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            style={{
              color: theme.neutrals.black_blue,
              fontSize: 12,
              lineHeight: '15.6px',
            }}
          >
            {footerSection}
          </Typography>
        </CardContent>
      </div>
      {/*       <CardActions disableSpacing>
        <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton>
          <ExpandMoreIcon />
        </IconButton>
      </CardActions> */}
    </Card>
  );
};

HorizontalCardLayout.defaultProps = {
  imageURL: 'https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png',
  subTitleSection: 'Waiting...',
  footerSection: 'Waiting...',
  descriptionSection: {
    currentRating: 0,
  }
};

HorizontalCardLayout.propTypes = {
  imageURL: PropTypes.string,
  titleCard: PropTypes.string.isRequired,
  subTitleSection: PropTypes.string,
  descriptionSection: PropTypes.object,
  footerSection: PropTypes.string,
};

export default HorizontalCardLayout;
