const palette = {
  primary: {
    main: '#37A989'
  },
  secondary: {
    main: '#8F3985'
  },
  error: {
    main: '#F46338',
    dark: '#E9260C',
  },
  green: {
    main:  '#37A989'
  },
  jungle: {
    main: '#37A989',
    dark: '#0B7049',
  },
  portland: {
    main: '#F46338',
    dark: '#E9260C',
  },
  honey: {
    main: '#FFB93F',
    dark: '#FF860F',
  },
  plum: {
    main: '#8F3985',
    dark: '#500C45',
  },
  carolina: {
    main: '#2E9ED0',
    dark: '#0861A9',
  },
  sky_blue: {
    main: '#5AC4D0',
    dark: '#1F96A9',
  },
  textSecondary: { main: '#FFFFF'},
  white: {main: '#FFFFFF'},
  black_blue:{ main: '#0B2F40'},
  grey: {main: '#A7B4BA'},
  header: {
    horizontal_gradient: 'linear-gradient(90deg, #0B2F40 21.35%, rgba(11, 47, 64, 0) 56.77%)',
    vertical_gradient: 'linear-gradient(360deg, #0B3041 12.5%, rgba(11, 47, 64, 0) 62.5%)'
  }

}

const neutrals = {
  white: '#FFFFFF',
  beige: {
    main: '#F8F7F1',
    dark: '#EBE8D6',
  },
  black_blue: '#0B2F40',
  grey: '#A7B4BA',
}

export {palette, neutrals};
export default {palette, neutrals};
