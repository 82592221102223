import { Typography, Link, } from '@material-ui/core';
import { palette } from '../styles/variables'
import { LazyCardMedia } from '../components/cards';



export default {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h1',
      },
    },
    h2: { component: Typography, props: { gutterBottom: true, variant: 'h3' } },
    h3: { component: Typography, props: { gutterBottom: true, variant: 'h4' } },
    h4: {
      component: Typography,
      props: { gutterBottom: true, variant: 'caption', paragraph: true },
    },
    p: { component: Typography, props: { paragraph: true } },
    a: { component: Link },
  },
};

export const blog = (color = palette.plum.main, hasBackground = false) => {

  const blogHeaderStyle = {
    color: hasBackground ? 'white' : color,
    marginTop: '2rem'
  }
  const textDefault = {
    color: hasBackground ? 'white' : 'inherit'
  }

  const linkStyle = {
    color: hasBackground ? 'white' : color,
  }
  return {
    overrides: {
      h1: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h1',
          style: blogHeaderStyle
        },
      },
      h2: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h2',
          style: blogHeaderStyle
        }
      },
      h3: {
        component: Typography,
        props: {
          gutterBottom: true,
          variant: 'h3',
          style: textDefault
        }
      },
      h4: {
        component: Typography,
        props: { gutterBottom: true, variant: 'h4', paragraph: true, style: textDefault },
      },
      p: {
        component: Typography,
        props: {
          paragraph: true,
          style: { lineHeight: '135%', ...textDefault }
        }
      },
      a: { component: Link, props: { style: linkStyle } },
      img: {
        component: LazyCardMedia,
        props: {
          alt: "imágen ilustrativa",
          style: {
            width: '100%',
            paddingTop: '100%',
            margin: `12px 0px`,
            borderRadius: `5px`
          }
        }
      }
    },
  };
}