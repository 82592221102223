import React from 'react'
import { Typography, CardMedia, Container} from '@material-ui/core';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: theme.spacing(1,1,0),
    // backgroundColor: theme.neutrals.white,
    // marginBottom: theme.spacing(0.5),
    borderRadius: 5,
    overflow: 'hidden',
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 25,
  },
  content: {
    verticalAlign: 'baseline',
    height: '100%',
    width: '100%',
    padding: theme.spacing(1, 1),
  }

}))


function ProcedureCardSmall ({link,  name, image, backgroundColor}) {
  const classes = useStyles();
  const theme = useTheme();
  const ProcedureCardSmallTemplate = () => (
    <Container 
      className={classes.root} 
      style={{backgroundColor: backgroundColor  || theme.neutrals.white 
    }}>
      <CardMedia className={classes.image} image={image} title="procedure name" />
      <div className={classes.content}>
        <Typography variant="h4" color="initial" >
          {name}
        </Typography>
      </div>
    </Container>
  )
  
  if(link && link.length > 0){
    return (
      <Link to={link} >
        {ProcedureCardSmallTemplate()}
      </Link>
    );
  }
  return ProcedureCardSmallTemplate()
};

ProcedureCardSmall.defaultProps= {
  link: '',
  color: '#fff',
}

ProcedureCardSmall.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  image: PropTypes.string.isRequired,
  color: PropTypes.string,
}


export default ProcedureCardSmall;
