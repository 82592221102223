/**
 * Consists of all the constants for various available routes within our app.
 * Reason is simply to avoid typos and easy renaming of routes when required.
 */
export const HOME = "/";
// Info -- Open Views
export const CONOCENOS = "/conocenos";
export const CONTACTANOS = "/contactanos";
export const NUESTROS_PROCEDIMIENTOS = "/nuestros-procedimientos";
export const NUESTROS_MEDICOS = "/nuestros-medicos";
export const LOGIN = "/login";

export const PROCEDURE_DETAIL = "/nuestros-procedimientos/:slug";
export const MEDICOS_DETAIL = "/nuestros-medicos/:slug";
export const COMPARAR = "/comparar";
export const CALCULATOR = "/calculator";

