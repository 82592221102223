import { AppBar, Box, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  logo: {
    maxHeight: 32,
    marginTop: 5,
    maxWidth: 70.21,
  },
  inputInput: {
    fontSize: 14,
    padding: theme.spacing(1, 0, 1, 0),
    paddingLeft: `0.75em`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
    '& input::placeholder': {
      color: '#A7B4BA',
      fontFamily: 'Open Sans',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 18.2,
    },
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  root: {
    flexGrow: 1,
  },
  search: {
    alignSelf: 'flex-start',
    borderRadius: theme.shape.borderRadius,
    flexGrow: 1,
    marginTop: 7,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  toolbar: {
    alignItems: 'flex-middle',
    minHeight: 10,
    paddingTop: theme.spacing(1), 
  },
  toolbarContainer: {
    maxHeight: 120,
  },
}));

const TopBar = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={classes.toolbarContainer}
        style={{ backgroundColor: '#F8F7F1', padding:`17px 0` }}
      >
        <Toolbar className={classes.toolbar}>
          {/* TODO: This should be a Logo Component
          the top bar changes when a user is log in */}
          <Box component="a" href="/" display="flex" flexGrow={1}>
            <img
              className={classes.logo}
              alt="Tani Salud Brand Logo"
              src={`${process.env.PUBLIC_URL}/images/logo-main.png`}
            />
          </Box>
        </Toolbar>
        
      </AppBar>
    </div>
  );
};

export default TopBar;
