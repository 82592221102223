// change between input types when in focus
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { InputBase as Input } from '@material-ui/core';
import { priceFormat } from './calculatorFunction';
import './ChangingInput.css';

function ChangingInput({ ...props }) {
  const [isEditing, setIsEditing] = useState(false);
  function toggleEditing() {
    setIsEditing(!isEditing);
  }

  if (isEditing) {
    return <Input {...props} type="number" onBlur={toggleEditing} />;
  }

  return (
    <Input
      {...props}
      type="text"
      value={priceFormat.format(props.value)}
      onFocus={toggleEditing}
      readOnly
    />
  );
}

export default ChangingInput;
