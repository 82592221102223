import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    doc01: {},
    doc02: {},
    procedure: {},
    step: {}
}

export const comparisonState = createSlice({
    name: 'comparison',
    initialState,
    reducers: {
        updateDoc01: () => {},
        updateDoc02: () => {},
        updateProcedure: () => {},
        incrementStep:  () => {},
        decrementStep: () => {},
        setStep: () => {}
    }
})

export const { 
    updateDoc01, 
    updateDoc02, 
    updateProcedure, 
    incrementStep,
    decrementStep,
    setStep } = comparisonState.actions

export default comparisonState.reducer