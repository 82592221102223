
export const priceFormat = new Intl.NumberFormat('es-MX', {
  style: 'currency',
  currency: 'MXN',
  maximumFractionDigits: 2
})

export const numberFormat = new Intl.NumberFormat('es-MX', {
  maximumFractionDigits: 2
})

export const calcComisionTani = (i, p = 15) => !Number.isNaN(i) ? (i * p) / 100 : 0

export const calcIvaSobreComision = (i, p = 15) => !Number.isNaN(i) ? (16 * i * p) / 10000 : 0

// recibe el total que le corresponde al médico (pago + isr)
export const calcISR = (i) => !Number.isNaN(i) ? i / 100 : 0

// recibe lo que se le paga al médico
export const altCalcISR = (i) => !Number.isNaN(i) ? (100 * i)/ 9900 : 0

export const calcComisionPasarela = (i) => !Number.isNaN(i) ? (i * 10) / 100 : 0

/* eslint-disable max-len */
// si queremos encontrar cuanto darle al cliente, partiendo del total
export const calcResult = (i, p = 15) => (
  !Number.isNaN(i)
    ? ((99 / 100) * i) * ((9000 - (116 * p)) / 10000)
    : 0
)
/* eslint-enable max-len */

// si queremos calcular el total, partiendo del monto esperado por el cliente
export function calcTaniTotal(i, p = 15) {
  return !Number.isNaN(i)
    ? (100 * (10000 * i)) / (99 * (9000 - (116 * p)))
    : 0
}
