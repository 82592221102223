/* eslint-disable max-len */
import React from 'react'
import { Grid, Typography, useMediaQuery } from "@material-ui/core"
import { useTheme, makeStyles } from "@material-ui/styles"
import Markdown from 'markdown-to-jsx';
import PropTypes from 'prop-types'


import { heart, talk, click} from '../../assets/images/financiamiento';
import markdownOptions from '../../utils/MarkdownOptions';
import {neutrals, palette} from '../../styles/variables'

const useStyle = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2),
      textAlign: 'center'

    }
  },
  textContaiener: {
    margin: '20px 15px 0 5px',
    '&:nth-child(2n + 1)': {
      textAlign: `left`
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'center'
    }
  },
  figure: {
    position: 'relative',
    margin: theme.spacing(2)
  },

}))

const defaultContent = [
  {
    title: "Préstamos rápidos <br/> y en línea",
    content: 'Es muy fácil aplicar a nuestro préstamo. Proceso en línea y asistido por uno de nuestros agentes. En menos de 48 horas tienes aprobado tu crédito.',
    image: {
      url: click,
      width: 195,
      height: 150,
      alt: 'Badge announcing our confident procedures'
    }
  },
  {
    title: 'Seleccionamos <br/> los mejores médicos',
    content: `Estarás en las mejores manos, todos nuestros médicos han sido verificados y cumplen con los más altos niveles de calidad.`,
    image: {
      url: heart,
      width: 195,
      height: 150,
      alt: 'A Price Tag'
    }
  },
  {
    title: 'Estamos disponibles para ti<wbr/>  en todo momento',
    content: `Estamos para escucharte y apoyarte personalmente desde que nos contactas hasta que te recuperas de la cirugía.`,
    image: {
      url: talk,
      width: 195,
      height: 150,
      alt: 'A hand holding a heart'
    }
  },

]
const useGridItemClass = ({width, height, maxWidth, minWidth, offset}) => {
  // ayuda a crear la clase correcta para los grid items
  const theme = useTheme()
  const baseRatio = height/width
  const smallHeight = baseRatio * minWidth
  const largeHeight = baseRatio  * maxWidth
  const useStyles = makeStyles({
    gridItem: {
      '& img': {
        width: minWidth,
        height: smallHeight,
      },
      '&:nth-child(even)': {
        textAlign: 'right',
        '& img': {
          marginRight: -offset
        }
      },
      '&:nth-child(odd)': {
        '& img': {
          marginLeft: -offset,
        },
      },
      [theme.breakpoints.up('md')]: {
        '& img': {
          margin: '0 !important',
          width: maxWidth,
          height: largeHeight,
        },
      }
    }
  })
  const {gridItem} = useStyles()
  return gridItem
}

const AcercaTani = ({contents, titleColor, contentColor, size}) => {
  const theme = useTheme()
  const classes = useStyle()
  const gridItemClass = useGridItemClass(size)
  const isTablet = useMediaQuery(theme.breakpoints.up('md'))
  const isSmall = useMediaQuery(theme.breakpoints.up('sm'))


  const calcDirection = (index) => {
    if (isTablet) {
      return 'column'
    }
    if (isSmall) {
      return 'column'
    }
    if (index % 2 !== 0) {
      return 'row-reverse'
    }
    return 'row'
  }
  const calcAlign = () => {
    if (isTablet) {
      return 'center'
    }
    return 'center'
  }

  return (
    <article className={classes.root}>

      <div style={{ marginTop: 15 }}>
        <Grid container justify="center">
          {contents.map((entry, index) => (
            <Grid
              className={gridItemClass}
              key={entry.title}
              container
              item
              xs={12}
              sm={4}
              md={Math.floor(12/contents.length)}
              direction={calcDirection(index)}
              justify="space-between"
              alignItems={calcAlign(index)}
            >
              <Grid item>
                <div className={classes.figure} >
                  <img
                    alt={entry.alt}
                    height={entry.image.height}
                    src={entry.image.url}
                    width={entry.image.width}
                  />
                </div>
              </Grid>

              <Grid
                item
                xs
                sm
                className={classes.textContaiener}
              >
                <Typography variant="h3" style={{marginBottom: 16, color: titleColor}}>
                  <Markdown options={markdownOptions}>{entry.title}</Markdown>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ color: contentColor }}
                >
                  <Markdown options={markdownOptions}>{entry.content}</Markdown>
                </Typography>
              </Grid>
            </Grid>
          ))
          }
        </Grid>


      </div>

    </article>
  )
}
AcercaTani.propTypes = {
  contents: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string,
    image: PropTypes.shape({
      url: PropTypes.string,
      height: PropTypes.number,
      width:PropTypes.number
    })
  })),
  titleColor: PropTypes.string,
  contentColor: PropTypes.string,
  size: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number
  })
}
AcercaTani.defaultProps ={
  contents: defaultContent,
  titleColor: palette.portland.main,
  contentColor: neutrals.black_blue,
  size: {
    width: 195,
    height: 150,
    maxWidth: 195,
    minWidth: 130,
    offset: 30
  }
}
export default AcercaTani;
