const sendWhatsappMessage = (
  message,
  number = process.env.REACT_APP_WA_NUMBER) => {
  const url = "https://api.whatsapp.com/send?phone=::formattedNumber&text=::encodedText"
    .replace("::formattedNumber", number)
    .replace("::encodedText", encodeURIComponent(message));

  window.open(url, '_blank', 'noopener, noreferrer');
}

const triggerCall = () => {
  const uri = `tel:${process.env.REACT_APP_CALL_CENTER_NUMBER}`
  window.open(uri, '_blank', 'noopener, noreferrer');
}

export {sendWhatsappMessage, triggerCall}
