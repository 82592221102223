import React from 'react'
import {PropTypes} from 'prop-types'
import { makeStyles, Typography} from '@material-ui/core'
import Icon from '../ui/Icon'

const useStyle = makeStyles(theme=> ({
    root: {
        color: theme.neutrals.black_blue,
        margin: theme.spacing(0,0,0,0),
        padding: theme.spacing(2,0),
        borderBottom: 'solid 1px ',
        '& > summary' : {
            listStyle: 'none'
        },
        '&:nth-last-child(1)': {
            borderBottom: 'none'
        }
    },
    summary: {
        listStyle: 'none',
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent:'space-between',
        alignContent: 'center',
        alignItems: 'center'

    },
    summaryIcon: {
        margin: theme.spacing(0,0,0,1)
    },
    expanded: {}
}))
const FAQcard = ({question, answer}) => {
    const classes = useStyle()
    return ( 
        <details className={classes.root}> 
            <summary className={classes.summary}>
                <div className={classes.summaryQuestion}>
                    <Typography variant="h3">
                        {question}
                    </Typography>
                    
                </div>
                <div className={classes.summaryIcon}>
                    <Icon name="hospital"/>
                </div> 
                
            </summary> 
            <p> {answer} </p> 
        </details>
)}

FAQcard.propTypes = {
    question: PropTypes.string.isRequired,
    answer: PropTypes.string.isRequired,
}

export default FAQcard