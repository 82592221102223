import React from 'react'
import { Typography, Box, useMediaQuery, Button } from '@material-ui/core'
import { useTheme } from '@material-ui/styles'
import { Icon } from '../../components/ui'
import {sendWhatsappMessage} from '../../utils/interactions'


const bg = 'https://tani-assets.s3.us-east-2.amazonaws.com/calculator/Calculator-image.png'
function HomeView () {
  const { breakpoints, palette } = useTheme()
  const isTablet = useMediaQuery(breakpoints.up('md'))
  const onSendWhatsapp = () => {
    sendWhatsappMessage(`¡Hola! Soy un cirujano en Tani y quiero mi link para calcular
      los precios de mis cirugías.
    `)
  }
  return (
    <Box
      id="container"
      width="100%"
      height="100vh"
      display="flex"
      alignItems={isTablet ? 'center': 'flex-end'}
      justifyContent="flex-start"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      }}
    >
      <div id="gradient"
        style={ isTablet
          ? {
            width: '100%',
            height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 0,
              background: palette.header.horizontal_gradient
          } : {
            background: palette.header.vertical_gradient,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 0,
          }}>&nbsp;</div>
      <Box p={6} maxWidth={520} zIndex="10" color="white.main">
        <Typography variant="h1" paragraph>
          Bienvenido a la calculadora de Tani
        </Typography>
        <Typography paragraph>
          Pidele a tu asesor de Tani que te comparta tu
          link de calculadora para que
          puedas comenzara a determinar el precio que quieres
          pulicar para cada una de tus cirugías
          en Tani.
        </Typography>
        <Button
          variant="contained"
          onClick={onSendWhatsapp}
          style={{ color: 'white', backgroundColor: palette.honey.main, marginTop: '1rem' }}
          startIcon={
            <Icon name="whatsapp-blue" strokeWidth={0} />
          }
        > Contactar a un asesor </Button>
      </Box>
    </Box>
  )
}

export default HomeView
