import '@fontsource/baloo-tammudu-2';
import {ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {RouterConfig} from './navigation/RouterConfig';
import customTheme from './styles/taniTheme';
// import './utils/dropConsole';

const App = () => (
  <ThemeProvider theme={customTheme}>
    <CssBaseline />
    <Router>
      <RouterConfig />
    </Router>
  </ThemeProvider>
);

export default App;