import React from 'react';

import {
  Container,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  InputAdornment,
  // InputBase as Input,
  Typography,
  FormControl,
  Button,
  // InputLabel,
  makeStyles,
  Select,
  MenuItem,
  useMediaQuery,
  useTheme,
  // Grid,
  Box,
  FormHelperText,
  Divider,
  Tooltip,
  Link,
} from '@material-ui/core';
import {
  CreditCard,
  AccountBalanceWallet,
  KeyboardArrowDown,
  Add,
  Remove,
} from '@material-ui/icons';

import { PropTypes } from 'prop-types';

import { Section } from '../../components/boxes';
import {
  calcResult,
  calcComisionTani,
  calcIvaSobreComision,
  altCalcISR,
  calcComisionPasarela,
  priceFormat,
  calcTaniTotal,
} from './calculatorFunction';
import { typography } from '../../styles/GlobalStyles';
import { Hero } from '../../components/ui';
// import CalculatorMoreInfo from './CalculatorMoreInfo';
import Input from './ChangingInput';

const contentRender = () => ({
  title: 'Calcula el precio de tus cirugías en Tani',
  subtitle: `
        Usa este simulador de precios para determinar
        el precio que quieres publicar para cada una
        de tus cirugías en Tani.`,
  inputLabel: 'Si publicas y comunicas un precio para tu cirugía de',
  inputPlaceholder: 'e.g. 10,000',
  resultLabel: 'Recibirás en total',
  comisionTani: `Comisión de Tani `,
  iva: 'IVA sobre comisión',
  isr: 'ISR ',
  pasarela: 'Descuento por pago de contado. ',
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.neutrals.white,
  },
  illustration: {
    height: 178,
    width: 151,
    position: 'absolute',
    right: 0,
    bottom: 30,
  },
  line: {
    padding: theme.spacing(1, 0),
  },
  smallItalic: typography.smallItalic,
  result: {
    fontSize: 18,
    color: theme.palette.green.main,
    fontWeight: 600,
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  containerCard: {
    [theme.breakpoints.up('md')]: {
      borderRadius: 20,
      boxShadow: '0px 6px 8px rgba(11, 47, 64, 0.1);',
      padding: theme.spacing(4, 3),
      backgroundColor: theme.neutrals.beige.main,
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent !important',
    },
  },
}));

const CalculatorView = ({ percent }) => {
  const { breakpoints, neutrals, palette } = useTheme();
  const isTablet = useMediaQuery(breakpoints.up('md'));

  // Variables de estado para los inputs
  const [payment, setPayment] = React.useState(0); // lo que recibe el médico
  const [other, setOther] = React.useState(0);
  const [amount, setAmount] = React.useState(0); // el total sobre lo que se calcula todo
  const [contado, setContado] = React.useState(0);

  const [showOther, setShowOther] = React.useState(false);

  const selectOptions = [
    { value: 'msi', label: 'a meses' },
    { value: 'single', label: 'de contado' },
  ];
  const [select, setSelect] = React.useState(selectOptions[0].value);



  const classes = useStyles();

  function formatResult(number) {
    // le da formato a los numeros secundarios de los calculos
    return Number.parseFloat(number.toFixed(3));
  }

  const handlePaymentInput = (event) => {
    // calcula el total, si el usuario escribe el pago que quiere recibir
    const {
      target: { value: stringValue },
    } = event;
    const value = stringValue && parseFloat(stringValue) || stringValue;
    setPayment(value);

    const total = calcTaniTotal(value + other, percent);
    setAmount(formatResult(total));

    const calcContado = total - calcComisionPasarela(total);
    setContado(formatResult(calcContado));
  };

  const handleOtherInput = ({ target: { value: stringValue } }) => {

    const value = stringValue && parseFloat(stringValue) || stringValue;
    setOther(value);

    const total = calcTaniTotal(value + payment, percent);
    setAmount(formatResult(total));
    const calcContado = total - calcComisionPasarela(total);
    setContado(formatResult(calcContado));
  };

  const handleTotalInput = (event) => {
    // calcula el pago al cliente, si el usuaio escribe el total
    // value =  total  total
    const {
      target: { value: stringValue },
    } = event;
    const value = stringValue && parseFloat(stringValue) || stringValue;

    setAmount(value);

    const calcContado = value - calcComisionPasarela(value);
    setContado(calcContado);

    const pago = calcResult(value, percent);
    setPayment(pago - other);
  };

  const handleContadoInput = (event) => {
    // calcula el total y lo que hay que pagarle al cliente, si el médico escribe
    // el pago de contado de los clientes
    const {
      target: { value: stringValue },
    } = event;
    const value = stringValue && parseFloat(stringValue) || stringValue;

    setContado(value);

    const total = (value * 10) / 9;
    setAmount(total);

    const calcPayment = calcResult(total, percent);
    setPayment(calcPayment - other);
  };

  function handleSelect({ target: { value } }) {
    setSelect(value);
  }

  const toggleShowOther = () => {
    setShowOther(!showOther);
    handleOtherInput({ target: { value: 0 } });

  };

  const content = React.useMemo(() => contentRender(percent), [percent]);
  /* eslint-disable max-len */
  const cover = {
    mobile:
      'https://tani-assets.s3.us-east-2.amazonaws.com/surgeon-calculator/Calculator+-+Mobile.jpg',
    desktop:
      'https://tani-assets.s3.us-east-2.amazonaws.com/surgeon-calculator/Calculator+-+Desktop.jpg',
  };
  /* eslint-enable max-len */
  const heroImage = React.useMemo(() => (isTablet ? cover.desktop : cover.mobile), [isTablet]);

  return (
    <div style={{ marginTop: 80 }}>
      <Hero cover={heroImage}>
        <Box width={isTablet ? '40%' : '100%'}>
          <Typography paragraph variant="h1" style={{ color: neutrals.white }}>
            {content.title}
          </Typography>
          <Typography paragraph style={{ color: neutrals.white }}>
            {content.subtitle}
          </Typography>
        </Box>
      </Hero>

      <Section
        elevated
        isOffsetPlus
        backgroundColor={isTablet ? 'transparent' : neutrals.beige.main}
        zIndex={isTablet ? 91 : 85}
      >
        <Container maxWidth="md" className={classes.containerCard}>
          <article>
            <section id="result">
              <Box
                color="white.main"
                border={1}
                borderColor="white.main"
                p={1}
                borderRadius={8}
                bgcolor="black_blue.main"
              >
                <FormControl>
                  <Typography>Lo que quieres recibir por la cirugía</Typography>
                  <Input
                    autoFocus
                    style={{
                      fontSize: isTablet ? '1.6rem' : '1.2rem',
                      display: 'inline-block',
                      color: neutrals.white,
                    }}
                    inputProps={{
                      style: {
                        fontWeight: 'bolder',
                        fontFamily: 'Baloo Tammudu 2',
                      },
                    }}
                    type="number"
                    placeholder={content.inputPlaceholder}
                    value={payment}
                    onChange={handlePaymentInput}
                    initialValue={0}
                  />
                  {!showOther && (
                    <Box width="100%" textAlign="center" mt={1}>
                      <FormHelperText
                        style={{
                          fontStyle: 'italic',
                          color: neutrals.grey,
                        }}
                      >
                        No olvides incluir todos los costos asociados a la cirugía (clínica,
                        anestesiólogo, staff, etc).
                      </FormHelperText>
                    </Box>
                  )}
                </FormControl>
              </Box>

              {showOther && (
                <Box
                  border={1}
                  borderColor="grey.main"
                  p={1}
                  borderRadius={8}
                  bgcolor="white.main"
                  mt={2}
                >
                  <FormControl>
                    <Typography>Costos asociados </Typography>
                    <Input
                      style={{
                        fontSize: isTablet ? '1.6rem' : '1.2rem',
                        display: 'inline-block',
                      }}
                      inputProps={{
                        style: {
                          fontWeight: 'bolder',
                          fontFamily: 'Baloo Tammudu 2',
                        },
                      }}
                      type="number"
                      placeholder={content.inputPlaceholder}
                      value={other}
                      onChange={handleOtherInput}
                      initialValue={0}
                    />
                    <FormHelperText
                        style={{
                          fontStyle: 'italic',
                          color: neutrals.grey,
                        }}
                      >
                        Considera costos de la clínica,
                        anestesiólogo, staff, etc.
                      </FormHelperText>
                  </FormControl>
                </Box>
              )}
              <Button
                onClick={toggleShowOther}
                component={Link}
                color="textPrimary"
                endIcon={
                  showOther ? (
                    <Remove
                      style={{
                        backgroundColor: palette.carolina.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  ) : (
                    <Add
                      style={{
                        backgroundColor: palette.honey.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  )
                }
              >
                {!showOther ? 'Agregar costos asociados' : 'Remover costos asociados'}
              </Button>
            </section>

            <Box my={2} borderLeft={2} color="black_blue.main" marginLeft={2}>
              <List dense disablePadding style={{ position: 'relative', marginLeft: -27 }}>
                <ListItem item xs={6} id="isr">
                  <ListItemIcon>
                    <Add
                      fontSize="small"
                      style={{
                        backgroundColor: palette.honey.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary={content.isr}>
                    <Tooltip title="1% del pago antes de costos asociados">
                      <Typography component="span">
                        {priceFormat.format(altCalcISR(payment + other))}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                </ListItem>
                <ListItem item xs={6} id="comision-tani">
                  <ListItemIcon>
                    <Add
                      fontSize="small"
                      style={{
                        backgroundColor: palette.honey.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary={content.comisionTani}>
                    <Tooltip title={`${percent}% del total antes de descuentos`}>
                      <Typography component="span">
                        {priceFormat.format(calcComisionTani(amount, percent))}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                </ListItem>
                <ListItem item id="Iva" xs={6}>
                  <ListItemIcon>
                    <Add
                      fontSize="small"
                      style={{
                        backgroundColor: palette.honey.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary={content.iva}>
                    <Tooltip title="16% sobre la comisión antes de descuentos">
                      <Typography component="span">
                        {priceFormat.format(calcIvaSobreComision(amount, percent))}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                </ListItem>

                <ListItem item xs={6} id="pasarla">
                  <ListItemIcon>
                    <Add
                      fontSize="small"
                      style={{
                        backgroundColor: palette.honey.main,
                        color: 'white',
                        borderRadius: 12,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText secondary="Costo por financiamiento">
                    <Tooltip title="10% del total antes de descuentos">
                      <Typography component="span">
                        {priceFormat.format(calcComisionPasarela(amount))}
                      </Typography>
                    </Tooltip>
                  </ListItemText>
                </ListItem>
                {select === 'single' && <Divider />}
                {select === 'single' && (
                  <ListItem item xs={6} id="Subtotal">
                    <ListItemIcon>
                      <Box
                        width={20}
                        height={20}
                        bgcolor="black_blue.main"
                        borderRadius={12}
                        color="white.main"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        =
                      </Box>
                    </ListItemIcon>
                    <ListItemText secondary="Subtotal">
                      <Typography component="span">{priceFormat.format(amount)}</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {select === 'single' && (
                  <ListItem item xs={6} id="pasarla">
                    <ListItemIcon>
                      <Remove
                        fontSize="small"
                        style={{
                          backgroundColor: palette.green.main,
                          color: 'white',
                          borderRadius: 12,
                        }}
                      />
                    </ListItemIcon>
                    <ListItemText secondary={content.pasarela}>
                      <Typography component="span">
                        {priceFormat.format(calcComisionPasarela(amount))}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                )}
              </List>
            </Box>

            <section>
              <Box
                border={1}
                bgcolor="white.main"
                borderRadius={8}
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                overflow="hidden"
              >
                <FormControl style={{ paddingLeft: 8 }}>
                  <Typography>
                    Precio de la cirugía <sup>1</sup>
                  </Typography>
                  {select === 'msi' && (
                    <Input
                      style={{
                        fontSize: isTablet ? '1.6rem' : '1.2rem',
                        display: 'inline-block',
                      }}
                      inputProps={{
                        style: {
                          fontWeight: 800,
                        },
                      }}
                      type="number"
                      placeholder={content.inputPlaceholder}
                      value={amount}
                      onChange={handleTotalInput}
                      initialValue={0}
                    />
                  )}
                  {select === 'single' && (
                    <Input
                      style={{
                        fontSize: isTablet ? '1.6rem' : '1.2rem',
                        display: 'inline-block',
                      }}
                      inputProps={{
                        style: {
                          fontWeight: 800,
                        },
                      }}
                      type="number"
                      placeholder={content.inputPlaceholder}
                      value={contado}
                      onChange={handleContadoInput}
                      initialValue={0}
                    />
                  )}
                </FormControl>
                <FormControl
                  style={{
                    backgroundColor: neutrals.black_blue,
                    color: 'white',
                    padding: '0 1rem ',
                  }}
                >
                  <Select
                    disableUnderline
                    value={select}
                    onChange={handleSelect}
                    style={{ height: '100%', color: 'white' }}
                    IconComponent={() => <KeyboardArrowDown color="white" />}
                    startAdornment={
                      <InputAdornment position="start">
                        {select === 'msi' ? <CreditCard /> : <AccountBalanceWallet />}
                      </InputAdornment>
                    }
                  >
                    {selectOptions.map(({ value, label }) => (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </section>
          </article>
          <Box color="gray" mt={2} textStyle="italic">
            {select === 'msi' && (
              <Typography variant="caption" component="p">
                <sup>1</sup> Incluye la opción a meses sin intereses; Al momento de publicar
                redondeamos los precios para facilidad de tus pacientes.
              </Typography>
            )}
            {select === 'single' && (
              <Typography variant="caption" component="p">
                <sup>1</sup> Cuando un paciente paga de contado, se le aplica un descuento del 10%
              </Typography>
            )}
          </Box>
        </Container>
      </Section>
      {/* <CalculatorMoreInfo /> */}
    </div>
  );
};

CalculatorView.propTypes = {
  percent: PropTypes.number.isRequired,
};

export default CalculatorView;
