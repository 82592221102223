import React from 'react';
import {Route, Switch } from 'react-router-dom';

import Calculator from '../views/Calculator'
import HomeView from '../views/Home/HomeView'
import {
  HOME,
} from './CONSTANTS';

function HomeComponent() {
    return (
      <>
        <HomeView/>
        { /* <Link to="/B2E3D111"> 10% </Link>
        <Link to="/D48FD59D"> 12% </Link>
        <Link to="/90BC303F"> 15% </Link> */}
      </>
    )
  }

/* eslint-disable import/prefer-default-export */
export const RouterConfig = () => (
  <div>
    <Switch>
      {/* List all public routes here */}
      <Route exact path={HOME} >
        <HomeComponent />
      </Route>
      <Route exact path="/B2E3D111" component={() => <Calculator percent={10} />} />

      <Route exact path="/D48FD59D" component={() => <Calculator percent={12} />} />

      <Route exact path="/90BC303F" component={() => <Calculator percent={15} />} />

      {/* List all private/auth routes here */}
      {/* List a generic 404-Not Found route here */}
    </Switch>
  </div>
);
