/**
 * Tani Theme based on Material UI
 */

 import { createMuiTheme } from '@material-ui/core/styles';
 import { palette, neutrals } from './variables';
 import { typography, helpers } from './GlobalStyles'

 const theme = createMuiTheme({
   palette,
   neutrals,
   status: {
     success: '#0B7049',
     danger: '#E9260C',
     warning: '#FF860F',
     info: '#0861A9',

   },
   typography: {
     ...typography,
     color: neutrals.black_blue,

   },
   overrides: {
     MuiCssBaseline: {
       '@global': {
         html: {
           // maxWidth: 1920,
           marginLeft: 'auto',
           marginRight: 'auto',
           color: neutrals.black_blue,
           fontSize: 14,
           '@media ( min-width: 960px)': {
             fontSize: 16,
           }
         },

         body: {
           color: neutrals.black_blue,
           lineHeight: '1.3rem',
           backgroundColor: 'white'
         },
         a: {
           color: 'unset',
           textDecoration: 'none'
         },
         button: {
           background: 'unset',
           color: 'inherit',
           border: 'unset',
           padding: 'unset',
           font: 'unset',
           cursor: 'pointer',
           outline: 'unset'
         },
         hr: {
           color: neutrals.black_blue,
           backgroundColor: 'inherit',
           borderColor: 'inherit',
         }
       }
     },
     MuiButton: {
       root: {
         ...helpers.button,
         color: neutrals.black_blue,
       },
       outlined: {
         padding: '10px 28px',
         borderWidth: 2,
         borderColor: palette.jungle.main,
         color: palette.jungle.main,
       },
       outlinedPrimary: {
         borderWidth: 2,
         borderColor: palette.primary.main,
         color: palette.primary.main
       },
       outlinedSecondary: {
         borderWidth: 2,
         borderColor: palette.secondary.main,
         color: palette.secondary.main
       },
       contained: {
         backgroundColor: palette.jungle.main,
         color: 'white',
         '&:hover': {
           backgroundColor: palette.jungle.dark
         }
       },
       containedError: {
         borderColor: palette.error.main,
         color: 'white',
       },
       text: {
         color: 'inherit',
         fontWeight: 400,
         ...typography.body1,
       }
     },
     MuiIconButton: {
       root: {
         color: neutrals.black_blue,
       },
       outlined: {
         borderWidth: 2,
         borderColor: palette.jungle.main,
         color: palette.jungle.main,
       },
       outlinedPrimary: {
         borderWidth: 2,
         borderColor: palette.primary.main,
         color: palette.primary.main
       },
       outlinedSecondary: {
         borderWidth: 2,
         borderColor: palette.secondary.main,
         color: palette.secondary.main
       },
       contained: {
         backgroundColor: palette.jungle.main,
         color: 'white',
         '&:hover': {
           backgroundColor: palette.jungle.dark
         }
       },
       containedError: {
         borderColor: palette.error.main,
         color: 'white',
       },
       text: {
         color: 'inherit',
         fontWeight: 400,
         ...typography.body1,
       }
     },
     MuiPaper: {
       root: {
         color: 'inherit'
       }
     },
     MuiAccordion: {
       root: {
         '&$expanded': {
           '&::before': {
             opacity: 1
           },
           '&::after': {
             opacity: 1
           }
         }
       },

     },
     MuiAccordionSummary: {
       content: {
         '&$expanded': {
           margin: 0,
           minHeight: 'unset'
         }
       }
     },
     MuiMobileStepper: {
       root: {
         padding: 8,
         height: '100%',
         justifyContent: 'center',
         background: 'inherit'
       },
       dot: {
         width: 8,
         height: 8,
         margin: '0 4px'
       },
       dots: {

       },
       dotsActive: {
         background: palette.primary.main
       }
     },
     MuiRating: {
       sizeSmall: {
         fontSize: '1rem'
       }
     },
     MuiCheckbox: {
       root: {
         '&$checked': {
           color: palette.jungle.main
         }
       }

     },
     MuiSwitch: {
       colorSecondary: {
         '&$checked': {
           color: palette.jungle.dark,

         },
         '&$checked + $track': {
           backgroundColor: palette.jungle.dark,
           opacity: 0.3
         }

       },


     },
     MuiRadio: {
       colorSecondary: {
         '&$checked': {
           color: neutrals.white
         }
       }
     },
     MuiFormLabel: {
       root: {
         '&$focused': {
           color: palette.jungle.dark
         }
       }
     },
     MuiCardActionArea: {
       root: {
         '&:hover $focusHighlight': {
           opacity: 0,
         },
       }
     }
   }
 });

 export default theme;

