import { Container, Grid, Link, Typography } from '@material-ui/core';

import React from 'react';
import { Link as LinkRouter } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Icon from './Icon';

const footerStyles = {
  container: {
    marginLeft: 24,
  },
};

const useStyle = makeStyles((theme) => ({
  footerLegal: {
    color: '#fff',
    paddingBottom: 30,
    paddingTop: 10,
  },
  footerLegalLeft: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    },
    textAlign: 'left'
  },
  footerLegalRight: {
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    },
    textAlign: 'right'
  },
  footerMenu: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: 60
    },
    marginBottom: 33
  }
}));



const Footer = () => {
  const classes = useStyle();

  return (
    <footer style={{
      backgroundColor: '#234353',
      paddingBottom: 64
    }} >
      <div style={{
        backgroundColor: '#0B2F40',
        paddingTop: 100,
        paddingBottom: 32,
        width: '100%',
        marginTop: '-80px'
      }}>
        <Container maxWidth="lg">
          <Grid container spacing={2} style={{ paddingTop: 14 }}>
            <Grid item xs={12} md={5} lg={4}>
                <Link
                  component="a"
                  href="/"
                  variant="subtitle1"
                  style={{ color: '#fff', display: 'inline-flex' }}
                >
                  <img
                    alt="Tani Salud Brand Logo"
                    height={32}
                    src={`${process.env.PUBLIC_URL}/images/logo-main-white.png`}
                    width={70.21}
                  />
                </Link>
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  color: '#fff',
                  display: 'block',
                  marginTop: 33,
                  marginBottom: 33,
                  maxHeight: 58,
                  paddingRight: 18,
                }}
              >
                Tani es la plataforma que te permite encontrar el procedimiento médico que necesitas
                de
                forma fácil, rápida, segura y a un precio justo.
              </Typography>
            </Grid>
            <Grid item xs={12}md={5} lg={4}>
              <Typography
                variant="body2"
                color="inherit"
                style={{
                  color: '#fff',
                  display: 'block',
                  marginBottom: 33,
                  paddingRight: 18,
                }}
              >
                Si tienes preguntas o dudas, llámanos o mándanos un WhatsApp:
              </Typography>
              <div style={{ marginTop: 33 }}>

                <Link
                  component="a"
                  href="tel:5537084009"
                  variant="subtitle1"
                  style={{ color: '#fff', display: 'inline-flex' }}
                >
                  <Icon name="phone" strokeColor="#FFB93F" />
                  <span style={{ marginLeft: 8 }}>55 3708 4009</span>
                </Link>
              </div>
              <div style={{ color: '#fff', marginTop: 33 }}>
                <LinkRouter to={{
                  pathname:
                    "https://www.instagram.com/tani_salud/"
                }}
                  target="_blank">
                  <Icon name="ig-rounded" size={40} />
                </LinkRouter>
                <LinkRouter to={{
                  pathname:
                    "https://www.facebook.com/tanisalud/"
                }}
                  target="_blank">
                  <Icon container name="fb-rounded" size={40} style={footerStyles} />
                </LinkRouter>
                <LinkRouter to={{
                  pathname:
                    "https://vm.tiktok.com/ZM8JgjTob/"
                }}
                  target="_blank">
                  <Icon container name="tiktok-rounded" size={40} style={footerStyles} />
                </LinkRouter>
              </div>
            </Grid>

          </Grid>
        </Container>
      </div>
      <div style={{
        backgroundColor: '#234353',
        width: '100%',
      }}>
        <Container maxWidth="lg">
          <Grid container style={{ paddingTop: 32, textAlign: "center" }}>
            <Grid item
              xs={4} lg={2}>
              <Icon name="visa" strokeColor="transparent" size={60} />

            </Grid>
            <Grid item
              style={{ paddingLeft: 12 }}
              xs={4} lg={2}>
              <Icon name="mastercard" strokeColor="transparent" size={65} />
            </Grid>
            <Grid item
              style={{ paddingLeft: 12 }} xs={4} lg={2}>
              <Icon name="amex" strokeColor="transparent"size={65} />
            </Grid>
            <Grid item xs={4} lg={2}>
              <Icon name="spei"strokeColor="transparent" size={65} />
            </Grid>
            <Grid item xs={4} lg={2}>
              <Icon name="ml"strokeColor="transparent" size={80} />
            </Grid>
            <Grid item
              style={{ paddingRight: 12 }}
              xs={4} lg={2}>
              <Icon name="oxxo"strokeColor="transparent" size={65} />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} lg={6}>
              <Container
                maxWidth="xl"
                className={clsx(classes.footerLegal, classes.footerLegalLeft)}
              >
                <Typography
                  color="inherit"
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Open Sans',
                    lineHeight: '15.6px',
                    fontSize: '.9rem',
                    fontStyle: 'italic',
                    fontWeight: 400,
                  }}
                >
                  Tani Salud es una empresa de Polymath Ventures S. de R.L de C.V.
                </Typography>
                <br />
                <br />
                <Typography
                  color="inherit"
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Open Sans',
                    lineHeight: '15.6px',
                    fontSize: '.9rem',
                    fontStyle: 'italic',
                    fontWeight: 400,
                  }}
                >
                  Tlacotalpan #13 Int. 2-A, Roma Sur, 06760 Ciudad de México, CDMX
                </Typography>
              </Container>
            </Grid>
            <Grid item xs={12} lg={6}>
              <Container
                maxWidth="lg"
                className={clsx(classes.footerLegal, classes.footerLegalRight)}
                style={{ paddingBottom: 29 }}
              >
                <Typography
                  color="inherit"
                  style={{
                    display: 'inline-block',
                    fontFamily: 'Open Sans',
                    lineHeight: '15.6px',
                    fontSize: '.9rem',
                    fontStyle: 'italic',
                    fontWeight: 400,
                  }}
                >
                  @2021 {process.env.REACT_APP_NAME}
                </Typography>
                {/* <Link
                  component="a"
                  href="https://www.tanisalud.com/terminos-y-condiciones"
                  style={{
                    color: '#fff',
                    display: 'inline-block',
                    fontFamily: 'Open Sans',
                    lineHeight: '15.6px',
                    fontSize: '.9rem',
                    fontStyle: 'italic',
                    fontWeight: 400,
                  }}
                >
                  Términos y condiciones |
                </Link>
                <Link
                component="a"
                  href="https://www.tanisalud.com/politica-de-privacidad"
                  variant="caption"
                  style={{
                    color: '#fff',
                    display: 'inline-block',
                    fontFamily: 'Open Sans',
                    lineHeight: '15.6px',
                    fontSize: '.9rem',
                    fontStyle: 'italic',
                    fontWeight: 400,
                  }}
                >
                  Política de privacidad
                </Link> */}
              </Container>
            </Grid>
          </Grid>
        </Container>
      </div>
    </footer >
  );
};

export default Footer;
